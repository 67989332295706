<template>
  <div class="home CommerceAndTrade-wrap">
    <headert></headert>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/commerce.jpg" alt="">
        </div>
      </el-col>
    </el-row>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <el-row class="CommerceAndTrade-topbox" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <el-row type="flex" justify="space-between" class="IndustryCommerce-top-wrap">
          <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
            <div class="padding-tb70 WJgroup-title-wrap" >
              <div class="WJgroup-titleBox flex">
                <p class="WJgroup-title">{{$t('commerce.title')}}</p>
              </div>
            </div>
            <div class="leftBox" style="margin-top: 50px;">
              <div class="leftBox_content" v-html="typeInfo.content"></div>
            </div>
          </el-col>
          <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
            <div class="rightBox">
              <el-carousel :interval="5000" arrow="always" indicator-position='none'>
                <el-carousel-item v-for="(item,index) in sliderList" :key="index">
                   <img :src="item.litpic" alt="">
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>

        </el-row>
      </el-col>

    </el-row>
    <el-row class="CommerceAndTrade-botbox" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="23" :xl="18">
<!--        直营品牌集合店-->
        <div class="leftBox_title ">
          <h1>{{$t('commerce.brandTitle')}}</h1>
        </div>
        <div class="flex align-start BrandSales ">
          <div class="BrandSalesLeft">
            <div class="BrandSalesTitle">{{$t('commerce.brand')}}</div>
            <ul>
              <li @click="selectNews(index)" v-for="(item,index) in newsList" :key="index" class="flex" :class="{activeb:index==newsIndex}">{{item.title}} <img src="../../assets/image/Industry/arrow1.png" alt="" ></li>
            </ul>
          </div>
          <div class="BrandSalesright">
            <div v-if="newsList && newsList.length>newsIndex" v-html="newsList[newsIndex].content"></div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row  type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="WJgroup-titleBox flex padding-tb70">
<!--          维佳空间-->
          <p class="WJgroup-title">{{$t('commerce.space')}}</p>
        </div>
          <div class="WJ-font margin_t50">
            <p>{{$t('commerce.spaceDesc')}}</p>
          </div>
          <div class="WJspace">
            <a target="_blank" href="https://vr.3d66.com/vr/index_detail_684568.asp">

              <img v-if="lang=='zh'" src="../../assets/image/Commercial/huanyin.png" alt="">

              <img v-else src="../../assets/image/Commercial/huanyinen.png" alt="">
            </a>

          </div>
      </el-col>
    </el-row>

    <!-- 底部 -->
    <footerb></footerb>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
    data() {
      return {
        swiperOptions: {
          autoplay: {
            delay: 1000,
            disableOnInteraction: true
          },

          loop: true,
          effect: "fade",
          fade: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
            // progressbarOpposite: true,
          }
        },
        slideList: [{
            id: "42",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          }
        ],
        menuActiveIndex: '/IndustryCommerce',
        menu: {
          name: '产业布局',
          path: '/Industry',
          list: [{
            name: this.$t('menu.Property'),
            path: '/IndustryHouse',
          }, {
            name: this.$t('menu.park'),
            path: '/EntertainmentIndustrialPark',
          }, {
            name: this.$t('menu.entertainment'),
            path: '/Entertainment',
          }, {
            name: this.$t('menu.Commerce'),
            path: '/IndustryCommerce',
          }]
        },
        breadcrumb: [{
          name: this.$t('menu.layout'),
          path: '/Industry',
        }, {
          name: this.$t('menu.Commerce'),
          path: '/IndustryCommerce',
        }],
        typeid:77,
        typeInfo:{},
        sliderList:[],
        newsList:[],
        newsIndex:0
      };
    },
    computed: {
      lang(){
        return window.localStorage.getItem('language')
      },
    },
    created() {
      this.getData();
    },
    methods: {
      selectNews(index){
        this.newsIndex=index
      },
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';

        this.$request.post(api,{
          apiType_1:`ekey=1&typeid=${this.typeid}&addfields=content,content_en`,
          apiAdv_1:`ekey=1&pid=11`,
          apiArclist_1:`ekey=1&typeid=155&limit=100}&addfields=content,title_en,content_en`,//获取栏目文章列表
        }).then(res=>{
          this.typeInfo = this.$utils.translate(res.apiType[1].data)
          this.sliderList = this.$utils.translate(res.apiAdv[1].data)
          this.newsList = this.$utils.translate(res.apiArclist[1].data)
        })
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style>
.BrandSalesright img{
  width: 100% !important;
  object-fit: cover;
}
</style>
<style lang="less" scoped>
  .swiperIMg {
    width: 100%;
    height: auto;
  }

  .swiper-container {
    width: 100%;
    max-height: 670px;
  }

  .CommerceAndTrade-topbox {
    padding-bottom: 100px;
  }


  .house {
    .num {
      font-size: 93px;
      font-family: Impact;
      font-weight: 400;
      color: #af8e68;
    }

    .numRe {
      margin-left: 30px;
    }

    .com {
      display: flex;

      .qualityHouse {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1f2d3a;
        line-height: 40px;

      }

      .eng {
        height: 21px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #aaaaaa;
        margin-left: 11px;
      }
    }

    p {

      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1F2D3A;
      line-height: 24px;
      margin-top: 10px;

    }
  }

  .leftTitleOneActive {
    background: linear-gradient(to right, #e3caa6, #b5956f) !important;
    position: relative;
  }

  .leftTitleOneActive::after {
    content: '';
    position: absolute;
    top: 30px;
    right: 0;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #fff;
  }

  .leftTitleOne {
    background: linear-gradient(to right, #adafb2, #8f9294);
    // width: 100%;
    padding: 40px 50px;
  }

  .leftTitleOne h4 {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 38px;
  }

  .leftTitleOne h5 {

    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 30px;
  }

  .leftTitleOne h6 {
    margin-top: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
  }
 /deep/ .el-carousel__container{
     height: 390px;
  }
  .rightBox {
    padding-top: 107px;
    height: 389px;
    width: 100%;
    overflow: hidden;

  }

  .rightBox img {
    width: 100%;
    height: 100%;
  }

  .leftBox_title h1 {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1E2E3B;
    line-height: 46px;
  }

  .leftBox_title p {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #999999;
    line-height: 20px;
    margin-left: 10px;
  }

  .leftBox_subtitle {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #232D39;
    line-height: 72px;
    padding: 10px 0;
  }

  .leftBox_content {
    padding-left: 104px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #202E3A;
    line-height: 36px;
    text-indent: 2em;
  }

  .CommerceAndTrade-botbox {
    background-color: #eeeeee;
    padding: 100px 0 100px;
  }

  .Space_NameIMG {
    text-align: center;

  }

  .Space_NameIMG .Space_img {
    height: 86px;
    width: auto;
    padding: 70px 0;
  }

  .Space_NameIMG p {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #202E3A;
    line-height: 36px;
  }

  .Space_Ul {
    padding: 50px 0 80px;
  }

  .Space_Ul li {
    padding: 0 10px;
  }

  .Space_Ul li img {
    height: 43px;
    width: auto;

  }

  .Space_botimg {
    width: 100%;
    height: auto;
  }

  .BrandSales {
    width: 90%;
    margin: 80px auto;
  }

  .BrandSalesLeft {
    width: 300px;
    flex-shrink: 0;
    min-height: 526px;
    border: 1px solid #333333;
  }

  .BrandSalesTitle {
    padding: 30px 35px;
    // font-family: 'OPPOSans-M';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #333333;
  }

  .BrandSalesLeft li {
    padding-left: 85px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .BrandSalesLeft li img {
    display: none;
  }

  .BrandSalesLeft .activeb img {
    display: block;
    width: 10px;
    height: 11px;
    margin-left: 10px;
  }

  .BrandSalesright {
    flex: 1;
    border: 1px solid #333333;
    padding: 36px 44px;
    margin-left: 28px;
    min-height: 455px;
    font-size: 14px;
  }


  .BrandSalesr1 {
    width: 49.5%;
    position: relative;
    padding-top: 22.5%;
    overflow: hidden;
  }

  .BrandSalesr1 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .BrandSalesr2 {
    margin-top: 8px;
    margin-bottom: 20px;
    width: 32.7%;
    position: relative;
    padding-top: 16%;
    overflow: hidden;
  }

  .BrandSalesr2 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .WJspace{
    width: 100%;
    margin-top: 74px;
    margin-bottom: 131px;
  }
   .WJspace img{
     width: 100%;
   }
   .WJgroup-title-wrap{
     padding-top: 107px;
     padding-bottom: 40px;
   }

  @media screen and (min-width:320px) and (max-width:767px) {
    .IndustryCommerce-top-wrap{
      display: block;
      .el-col{
        float: none;
      }
      .leftBox_content{
        padding-left: 0;

      }
      .leftBox{
        margin-top: 0!important;
        .leftBox_content{
          font-size: 34px;
          line-height: 50px;
        }
      }
      .rightBox{
        padding-top: 70px;
      }

    }
    .CommerceAndTrade-wrap .BrandSales{
      //margin: 0!important;
      margin-top: 60px;
      margin-bottom: 00px;
      width: 100%!important;
      .BrandSalesTitle{
        padding: 30px 20px;
        font-size: 39px;
      }
      .BrandSalesLeft li{
        font-size: 34px;
        line-height: 50px;
        padding-left: 20px;

      }
      .BrandSalesLeft .activeb img{
      width: 26px;
        height: 26px;
      }
      .BrandSalesright{
        margin-left: 10px;
        padding: 20px;
      }
    }
    .WJ-font div, .WJ-font p{
      font-size: 34px;
      line-height: 50px;
    }
  }

</style>
